import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home';
import { AuthGuard } from './_helpers';
import { WelcomePageComponent } from './welcome-page/welcome-page.component';

const accountModule = () => import('./account/account.module').then(x => x.AccountModule);

const publicModule = () => import('./public/public.module').then(x => x.PublicModule);
const masterModule = () => import('./master/master.module').then(x => x.MasterModule);

const routes: Routes = [
    { path: '', component: WelcomePageComponent },
    /* { path: 'home', component: HomeComponent, canActivate: [AuthGuard] }, */
    { path: 'master', loadChildren: masterModule, canActivate: [AuthGuard] },
    
    { path: 'public', loadChildren: publicModule },
    { path: 'account', loadChildren: accountModule },

    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes,{useHash: true})],
    exports: [RouterModule]
})
export class AppRoutingModule { }