import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders,HttpParams, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { User } from '@app/_models';

@Injectable({ providedIn: 'root' })
export class AccountService {
    private userSubject: BehaviorSubject<User>;
    public user: Observable<User>;

    private logUserSubject: BehaviorSubject<any>;
    public logUser: Observable<any>;

    key = environment.MedLab_API_KEY;
    version = environment.MedLab_API_VERSION;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
        this.user = this.userSubject.asObservable();
        
        this.logUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('user')));
        this.logUser = this.logUserSubject.asObservable();

    }

    public get userValue(): User {
        return this.userSubject.value;
    }

    public get loginUserValue(): any {
  
        return this.logUserSubject.value;
    }

    login(email, password) {
        return this.http.post<User>(`${environment.MedLab_API_Root}/accounts/authenticate`, { email, password })
            .pipe(map(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
                this.userSubject.next(user);
                this.logUserSubject.next(user);
                return user;
            }));
    }

    logout() {
        // remove user from local storage and set current user to null
        localStorage.removeItem('user');
        this.userSubject.next(null);
        this.logUserSubject.next(null);
        this.router.navigate(['/account/login']);
    }

    register(user: User) {
        let headers = new HttpHeaders();
        //headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
        /* headers = headers.append('X-API-Key', `${this.key}`);
        headers = headers.append('X-API-Version', `${this.version}`); */
        headers = headers.append('Authorization', `Bearer ${this.userValue['jwtToken']}`);

        return this.http.post(`${environment.MedLab_API_Root}/accounts/register`, user ,this.prepareHeader(headers));
    }

    getAll() {
        let headers = new HttpHeaders();
        //headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
        /* headers = headers.append('X-API-Key', `${this.key}`);
        headers = headers.append('X-API-Version', `${this.version}`); */
        headers = headers.append('Authorization', `Bearer ${this.userValue['jwtToken']}`);

        return this.http.get(`${environment.MedLab_API_Root}/accounts`, this.prepareHeader(headers));
    }

    getAllEmployee() {
        let headers = new HttpHeaders();
        //headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
        /* headers = headers.append('X-API-Key', `${this.key}`);
        headers = headers.append('X-API-Version', `${this.version}`); */
        headers = headers.append('Authorization', `Bearer ${this.userValue['jwtToken']}`);
    
        return this.http.get<User[]>(`${environment.MedLab_API_Root}/accounts/employee`,this.prepareHeader(headers));
    }

    getById(id: string) {
        return this.http.get<User>(`${environment.MedLab_API_Root}/account/${id}`);
    }

    update(id, params) {
        return this.http.put(`${environment.MedLab_API_Root}/users/${id}`, params)
            .pipe(map(x => {
                // update stored user if the logged in user updated their own record
                if (id == this.userValue.id) {
                    // update local storage
                    const user = { ...this.userValue, ...params };
                    localStorage.setItem('user', JSON.stringify(user));

                    // publish updated user to subscribers
                    this.userSubject.next(user);
                    this.logUserSubject.next(user);
                }
                return x;
            }));
    }

    delete(id: string) {
        return this.http.delete(`${environment.MedLab_API_Root}/users/${id}`)
            .pipe(map(x => {
                // auto logout if the logged in user deleted their own record
                if (id == this.userValue.id) {
                    this.logout();
                }
                return x;
            }));
    }

    getUserName(){
        if(this.userValue){
            const firstName = this.userValue.firstName ? this.userValue.firstName : "";
            const lastName = this.userValue.lastName ? this.userValue.lastName : "";
            const role = this.userValue.role ? this.userValue.role : "";

            return firstName +" "+ lastName+"("+role+")";
        }else
            return "anonymous user";
    }

    verifyUser(token) {
        let headers = new HttpHeaders();
        //headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
        /* headers = headers.append('X-API-Key', `${this.key}`);
        headers = headers.append('X-API-Version', `${this.version}`); */
        
        return this.http.get(`${environment.MedLab_API_Root}/accounts/verify-email?token=${token}`, this.prepareHeader(headers));
    }

    forgotPassword(email) {
        let headers = new HttpHeaders();
        //headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
        /* headers = headers.append('X-API-Key', `${this.key}`);
        headers = headers.append('X-API-Version', `${this.version}`); */
        let body={
            email:email
        }
        
        return this.http.post(`${environment.MedLab_API_Root}/accounts/forgot-password`, body ,this.prepareHeader(headers));
    }

    validateResetToken(token) {
        let headers = new HttpHeaders();
        //headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
        /* headers = headers.append('X-API-Key', `${this.key}`);
        headers = headers.append('X-API-Version', `${this.version}`); */
        let body={
            token:token
        }
        
        return this.http.post(`${environment.MedLab_API_Root}/accounts/validate-reset-token`, body ,this.prepareHeader(headers));
    }

    resetPassword(token, password, confirmPassword) {
        let headers = new HttpHeaders();
        //headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
        /* headers = headers.append('X-API-Key', `${this.key}`);
        headers = headers.append('X-API-Version', `${this.version}`); */
        let body={
            token:token,
            password:password,
            confirmPassword:confirmPassword
        }
        
        return this.http.post(`${environment.MedLab_API_Root}/accounts/reset-password`, body ,this.prepareHeader(headers));
    }

    changePassword(email, oldPassword, password, confirmPassword) {
        let headers = new HttpHeaders();
        //headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
        /* headers = headers.append('X-API-Key', `${this.key}`);
        headers = headers.append('X-API-Version', `${this.version}`); */
        let body={
            email:email,
            oldPassword:oldPassword,
            password:password,
            confirmPassword:confirmPassword
        }
        
        return this.http.post(`${environment.MedLab_API_Root}/accounts/change-password`, body ,this.prepareHeader(headers));
    }

    public prepareHeader(headers: HttpHeaders | null): any {
        headers = headers || new HttpHeaders();
        /* headers = headers.append('X-API-Key', `${this.key}`);
        headers = headers.append('X-API-Version', `${this.version}`); */
        return {
            headers: headers
      };
    }
}