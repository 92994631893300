import { Injectable } from '@angular/core';
import { HttpAuthService } from './http-auth.service';
import { HttpHeaders, HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AccountService } from '../_services/account.service';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  key = environment.MedLab_API_KEY;
  version = environment.MedLab_API_VERSION;

  constructor(private accountService: AccountService, private http: HttpClient, private httpauth : HttpAuthService) { }

  download(url: string): Observable<Blob> {
    return this.http.get(url, {
      responseType: 'blob'
    })
  }

  changeStatusofFile(id:number, isvisible): Observable<any> {
    let headers = new HttpHeaders();
    //headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
    /* headers = headers.append('X-API-Key', `${this.key}`);
    headers = headers.append('X-API-Version', `${this.version}`); */
    headers = headers.append('Authorization', `Bearer ${this.accountService.userValue['jwtToken']}`);
    let body={
      isvisible:isvisible
    }
    if (!id) {
      return
    }
    
    return this.httpauth.put(environment.MedLab_API_Root + '/clientAttachment/'+id, body ,this.prepareHeader(headers));
  }

  deleteFile(id:number): Observable<any> {
    let headers = new HttpHeaders();
    //headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
    /* headers = headers.append('X-API-Key', `${this.key}`);
    headers = headers.append('X-API-Version', `${this.version}`); */
    let params = new HttpParams();

    if (!id) {
      return
    }
    
    return this.httpauth.delete(environment.MedLab_API_Root + '/clientAttachment/'+id,headers);
  }

  uploadFile(fileData:any, remark:string): Observable<any> {
    let headers = new HttpHeaders();
    //headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
   /*  headers = headers.append('X-API-Key', `${this.key}`);
    headers = headers.append('X-API-Version', `${this.version}`); */
    let params = new HttpParams();

    if (!fileData) {
      return
    }

    let data = {
      "remark": remark
    }

     const formData: FormData = new FormData();
    /*for (let i = 0; i < fileData.length; i++) {
      formData.append(i.toString(), fileData[i], fileData[i].name);
    } */
    formData.append("file", fileData, fileData.name );
    formData.append("remark", remark );
    
    const options = {
      reportProgress: true,
      observe: 'events',
      headers
    };
  return this.http.post(environment.MedLab_API_Root + '/clientAttachment', formData,{
    reportProgress: true,
    observe: 'events',
    headers
  }).pipe(catchError(this.errorMgmt));

  }

  public prepareHeader(headers: HttpHeaders | null): any {
    headers = headers || new HttpHeaders();
    /* headers = headers.append('X-API-Key', `${this.key}`);
    headers = headers.append('X-API-Version', `${this.version}`); */
    return {
        headers: headers
    };
  }

  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
      
    } else {
      // Get server-side error
      errorMessage = `Message: ${error.error.message}`;
    }
    //return errorMessage;
   /*  return throwError(() => {
      return errorMessage;
    }); */
    return throwError(errorMessage);
  }
}
