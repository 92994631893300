<mat-card class="card">
    <!-- content -->
    <mat-card-content>
        <div fxLayout="column">
            <!-- Column-1 -->
            <div  class="main-content body-logo" fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
                <img src="../../assets/logo/MedLab_logo.png">
                
            </div>
            <!-- <div class="row">
                <h2>Welcome to MedlabPharma Web App</h2>
                <p>Quick Links</p><br>
                <ul>
                    <li>Client - New Query Form</li>
                    <li>Vendor - Registration Form</li>
                    <li>Dashboard</li>
                    <li>Login</li>
                </ul>
            </div> -->
            
            
                <mat-divider></mat-divider>
                <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center"><span class="main-title">Welcome to MedlabPharma Web App</span></div>
                <div class="mt-frm-div" fxFlex="column" fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="100">
                    <table fxFlex="40">
                        <tr>
                        <th>
                            <p>Quick Links</p>
                        </th>
                        <th>
                            <p>Share Links</p>
                        </th>
                        </tr>
                        <tr>
                            <td>
                                <li><a routerLink="/public/order">Client - New Query Form</a></li>
                            </td>
                            <td>
                                <button mat-button [cdkCopyToClipboard]="clientShareLink" (click)="CopyToClipboard()">
                                    <mat-icon class="link-icon">link</mat-icon>
                                  </button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <li><a routerLink="/public/vendor">Vendor - Registration Form</a></li>
                            </td>
                            <td>
                                <button mat-button [cdkCopyToClipboard]="vendorShareLink" (click)="CopyToClipboard()">
                                    <mat-icon class="link-icon">link</mat-icon>
                                  </button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                &nbsp;
                            </td>
                            <td>&nbsp;</td>
                        </tr> 
                        <tr>  
                            <td>
                                <li><a routerLink="/account/login">Login</a></li>
                            </td>
                            <td>&nbsp;</td>
                        </tr>
                    </table>          
                </div>
        </div>
        
    </mat-card-content>
</mat-card>