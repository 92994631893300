import { Component, OnInit, ViewChild } from '@angular/core';
import { AccountService } from '../_services';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit {
  @ViewChild(MatSidenav)
  sidenav!: MatSidenav;
  
  constructor(private accountService:AccountService) { }

  ngOnInit(): void {
  }

  logout() {
    this.accountService.logout();
}

}
