<!-- <mat-toolbar class="mat-elevation-z8">
    <button mat-icon-button (click)="isExpanded = !isExpanded">
        <mat-icon *ngIf="!(isExpanded || isShowing)">
            menu
        </mat-icon>
        <mat-icon *ngIf="isExpanded || isShowing">
            close
        </mat-icon>
    </button>
    <div class="logo less-c12g4">
        <img src="../../assets/logo/MedLab_logo.png">
    </div>
    <div class="toolbar-title">
        <h1>{{pageTitle}}</h1>
    </div>
</mat-toolbar>
<mat-sidenav-container class="mt-container" autosize [style.marginTop.px]="isBiggerScreen() ? 56 : 0">
    <mat-sidenav #sidenav="matSidenav" [mode]="isBiggerScreen() ? 'over' : 'side'" class="mt-sidenav" [(opened)]="opened" (mouseenter)="mouseenter()"
        (mouseleave)="mouseleave()" fixedTopGap="56">
        <mat-nav-list *ngIf="!(user | async)">
            <div (click)="showSubmenu = !showSubmenu">
                <mat-icon mat-list-icon *ngIf="!(isExpanded || isShowing)">supervised_user_circle</mat-icon>
                <div *ngIf="isExpanded || isShowing">
                    <img class="avatar mat-elevation-z8" src="../../assets/user.png" />
                    <br>
                    <h4 class="name">Hello Concern</h4>
                </div>
            </div>
            <mat-divider></mat-divider>
            <mat-list-item (click)="showSubmenu = !showSubmenu" class="parent" routerLink="/public/order">
                <span class="full-width menu-body" *ngIf="isExpanded || isShowing">Client<p class="full-width"
                        *ngIf="isExpanded || isShowing">(New Query Form)</p></span><br>

                <mat-icon mat-list-icon>person_add</mat-icon>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item (click)="showSubmenu = !showSubmenu" class="parent" routerLink="/public/vendor">
                <span class="full-width menu-body" *ngIf="isExpanded || isShowing">Vendor<p class="full-width"
                        *ngIf="isExpanded || isShowing">(Registration Form)</p></span><br>

                <mat-icon mat-list-icon>supervised_user_circle</mat-icon>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item (click)="showSubmenu = !showSubmenu" class="parent" routerLink="/account/login">
                <span class="full-width menu-body" *ngIf="isExpanded || isShowing">Login<p class="full-width"
                        *ngIf="isExpanded || isShowing"></p></span><br>

                <mat-icon mat-list-icon>help</mat-icon>
            </mat-list-item>
        </mat-nav-list>
        <mat-nav-list *ngIf="(user | async)">
            <div (click)="showSubmenu = !showSubmenu">
                <mat-icon mat-list-icon *ngIf="!(isExpanded || isShowing)">supervised_user_circle</mat-icon>
                <div *ngIf="isExpanded || isShowing">
                    <img class="avatar mat-elevation-z8" src="../../assets/user.png" />
                    <br>
                    <div *ngIf="userName && account.role">
                    <h3 class="name">Hello {{userName}}</h3>
                       <span> ({{account.role}})</span>
                    </div>
                </div>
            </div>
            <mat-list-item (click)="showSubmenu = !showSubmenu" class="parent" routerLink="/master/home">
                <span class="full-width menu-body" *ngIf="isExpanded || isShowing">Manage Leads</span><br>

                <mat-icon mat-list-icon>leaderboard</mat-icon>
            </mat-list-item>
            <mat-list-item *ngIf="!(account.role=='Coordinator')" (click)="showSubmenu = !showSubmenu" class="parent" routerLink="/master/manageVendors">
                <span class="full-width menu-body" *ngIf="isExpanded || isShowing">Manage Vendors</span><br>

                <mat-icon mat-list-icon>contacts</mat-icon>
            </mat-list-item>
            <mat-list-item (click)="showSubmenu = !showSubmenu" class="parent" routerLink="/master/manageClients">
                <span class="full-width menu-body" *ngIf="isExpanded || isShowing">Manager Clients</span><br>

                <mat-icon mat-list-icon>supervisor_account</mat-icon>
            </mat-list-item>
            <mat-list-item *ngIf="account.role=='Admin'" (click)="showSubmenu = !showSubmenu" class="parent" routerLink="/master/manageUsers">
                <span class="full-width menu-body" *ngIf="isExpanded || isShowing">Manager Users</span><br>

                <mat-icon mat-list-icon>group_work</mat-icon>
            </mat-list-item>
            <mat-list-item (click)="showSubSubMenu = !showSubSubMenu" class="parent">
                <span class="full-width" *ngIf="isExpanded || isShowing">Settings</span><br>
                <mat-icon mat-list-icon>settings</mat-icon>
                <mat-icon class="menu-button" [ngClass]="{'rotated' : showSubSubMenu}" *ngIf="isExpanded || isShowing">expand_more</mat-icon>
            </mat-list-item>
            <div class="submenu" [ngClass]="{'expanded' : showSubSubMenu}" *ngIf="showSubSubMenu && isExpanded">
                <mat-list-item routerLink="/master/manageCompany">Company<br><mat-icon mat-list-icon>business</mat-icon></mat-list-item>
                <mat-list-item routerLink="/master/invoiceSettings">Invoice<br><mat-icon mat-list-icon>plagiarism</mat-icon></mat-list-item>
            </div>
            <mat-list-item *ngIf="account.role=='Admin'" (click)="showSubmenu = !showSubmenu" class="parent" routerLink="/master/email-settings">
                <span class="full-width menu-body" *ngIf="isExpanded || isShowing">Email Service</span><br>

                <mat-icon mat-list-icon>email</mat-icon>
            </mat-list-item>
            <mat-list-item *ngIf="account.role=='Admin'" (click)="showSubmenu = !showSubmenu" class="parent" routerLink="/master/reports">
                <span class="full-width menu-body" *ngIf="isExpanded || isShowing">Reports</span><br>

                <mat-icon mat-list-icon>timeline</mat-icon>
            </mat-list-item>
            <mat-list-item (click)="showSubmenu = !showSubmenu" class="parent" routerLink="/master/change-password">
                <span class="full-width menu-body" *ngIf="isExpanded || isShowing">Change Password</span><br>

                <mat-icon mat-list-icon>lock_open</mat-icon>
            </mat-list-item>
            <mat-list-item (click)="showSubmenu = !showSubmenu" class="parent" (click)="logout()">
                <span class="full-width menu-body" *ngIf="isExpanded || isShowing">Logout</span><br>

                <mat-icon mat-list-icon>exit_to_app</mat-icon>
            </mat-list-item>
        </mat-nav-list>
    </mat-sidenav>
    <alert></alert>
    <div class="mt-sidenav-content">
        <main>
            <div class="content">
                <router-outlet></router-outlet>
            </div>
        </main>
    </div>

</mat-sidenav-container> -->
<!-- </nav> -->
<!-- <mat-sidenav-container>
    <mat-sidenav  mode="side" opened #sidenav="matSidenav" class="mat-elevation-z8">
        <div *ngIf="user">
            <img class="avatar mat-elevation-z8" src="https://source.unsplash.com/c_GmwfHBDzk/200x200" />

            <h4 class="name">John Smith</h4>
            <p class="designation">Software Engineer</p>

            <mat-divider></mat-divider>
            
            <button mat-button class="menu-button" routerLink="/home">
                <mat-icon>home</mat-icon>
                <span>Home</span>
            </button>
            <button mat-button class="menu-button" routerLink="/home">
                <mat-icon>person</mat-icon>
                <span>Profile</span>
            </button>
            <button mat-button class="menu-button" routerLink="/home">
                <mat-icon>info</mat-icon>
                <span>About</span>
            </button>
            <mat-divider></mat-divider>
            <button mat-button class="menu-button" routerLink="/home">
                <mat-icon>help</mat-icon>
                <span>Help</span>
            </button>
        </div>
        <div *ngIf="!user">
            <img class="avatar mat-elevation-z8" src="../../assets/user.png" />

            <h2 class="name">Hello Concern</h2>

            <mat-divider></mat-divider>
            
            <button mat-button class="menu-button" routerLink="/public/order">
                <mat-icon>person_add</mat-icon>
                <span>Client</span>
                <p>(New Query Form)</p>
            </button>
            <button mat-button class="menu-button" routerLink="/public/vendors">
                <mat-icon>supervised_user_circle</mat-icon>
                <span>Vendor</span>
                <p>(Registration Form)</p>
            </button>
            <mat-divider></mat-divider>
            <button mat-button class="menu-button" routerLink="/account/login">
                <mat-icon>help</mat-icon>
                <span>Login</span>
            </button>
        </div>
    </mat-sidenav>
    <mat-sidenav-content>
        <div class="content mat-elevation-z8">
          <router-outlet></router-outlet>
        </div>
      </mat-sidenav-content>
</mat-sidenav-container> -->

<mat-toolbar class="mat-elevation-z8" *ngIf="!hasRoute('/master/invoicePrint')">
    <button mat-icon-button (click)="sidenav.toggle()" *ngIf="!hasRoute('/public/repeatOrder') && !hasRoute('/public/vendorProposol') && !hasRoute('/public/order') && !hasRoute('/public/vendor')">
        <mat-icon>
            menu
        </mat-icon>
    </button>
    <div class="logo less-c12g4">
        <img src="../../assets/logo/MedLab_logo.png">
    </div>
    <div class="toolbar-title" *ngIf="!isBiggerScreen()">
        <h1>{{pageTitle}}</h1>
    </div>
</mat-toolbar>
<div class="toolbar-title" *ngIf="isBiggerScreen()" style="margin-top: 30px; text-align: center; margin-bottom: -40px; color: #000;">
    <h1>{{pageTitle}}</h1>
</div>
<mat-sidenav-container class="mt-container" autosize [style.marginTop.px]="isBiggerScreen() ? 56 : 0">
    <mat-sidenav *ngIf="!hasRoute('/public/repeatOrder') && !hasRoute('/public/vendorProposol') && !hasRoute('/public/order') && !hasRoute('/public/vendor') && !hasRoute('/master/invoicePrint')" #sidenav="matSidenav" [mode]="isBiggerScreen() ? 'over' : 'side'" class="mt-sidenav" [(opened)]="opened" (mouseenter)="mouseenter()"
        (mouseleave)="mouseleave()" fixedTopGap="56">
        <mat-nav-list *ngIf="!(user | async)">
            <div (click)="showSubmenu = !showSubmenu">
                <div>
                    <img class="avatar mat-elevation-z8" src="../../assets/user.png" />
                    <br>
                    <h4 class="name">Hello Concern</h4>
                </div>
            </div>
            <mat-divider></mat-divider>
            <mat-list-item (click)="showSubmenu = !showSubmenu" class="parent" routerLink="/public/order">
                <span class="full-width menu-body" *ngIf="isExpanded || isShowing">Client<p class="full-width"
            >(New Query Form)</p></span><br>

                <mat-icon mat-list-icon>person_add</mat-icon>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item (click)="showSubmenu = !showSubmenu" class="parent" routerLink="/public/vendor">
                <span class="full-width menu-body">Vendor<p class="full-width"
                        *ngIf="isExpanded || isShowing">(Registration Form)</p></span><br>

                <mat-icon mat-list-icon>supervised_user_circle</mat-icon>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item (click)="showSubmenu = !showSubmenu" class="parent" routerLink="/account/login">
                <span class="full-width menu-body">Login<p class="full-width"
                        *ngIf="isExpanded || isShowing"></p></span><br>

                <mat-icon mat-list-icon>help</mat-icon>
            </mat-list-item>
        </mat-nav-list>
        <mat-nav-list *ngIf="(user | async)">
            <div (click)="showSubmenu = !showSubmenu">
                <div *ngIf="isExpanded || isShowing">
                    <img class="avatar mat-elevation-z8" src="../../assets/user.png" />
                    <br>
                    <div *ngIf="userName && account.role">
                    <h3 class="name">Hello {{userName}}</h3>
                       <span> ({{account.role}})</span>
                    </div>
                </div>
            </div>
            <mat-list-item (click)="showSubmenu = !showSubmenu" class="parent" routerLink="/master/dashboard">
                <span class="full-width menu-body">Dashboard</span><br>

                <mat-icon mat-list-icon>dashboard</mat-icon>
            </mat-list-item>
            <mat-list-item (click)="showSubmenu = !showSubmenu" class="parent" routerLink="/master/home">
                <span class="full-width menu-body">Manage Leads</span><br>

                <mat-icon mat-list-icon>leaderboard</mat-icon>
            </mat-list-item>
            <mat-list-item (click)="showSubmenu = !showSubmenu" class="parent" routerLink="/master/manageClients">
                <span class="full-width menu-body">Manage Clients</span><br>

                <mat-icon mat-list-icon>supervisor_account</mat-icon>
            </mat-list-item>
            <mat-list-item *ngIf="!(account.role=='Coordinator')" (click)="showSubmenu = !showSubmenu" class="parent" routerLink="/master/manageVendors">
                <span class="full-width menu-body">Manage Vendors</span><br>
                <mat-icon mat-list-icon>contacts</mat-icon>
            </mat-list-item>


            <!--  -->

            <mat-list-item *ngIf="!(account.role=='Coordinator')" (click)="showSubmenu = !showSubmenu" class="parent" routerLink="/master/masterReport">
                <span class="full-width menu-body">Master Report</span><br>
                <mat-icon mat-list-icon>timeline</mat-icon>
            </mat-list-item>

            <!--  -->

            <!-- <mat-list-item *ngIf="!(account.role=='Coordinator')" (click)="showSubSubMenu1 = !showSubSubMenu1" class="parent">
                <span class="full-width">Reports</span><br>
                <mat-icon mat-list-icon>timeline</mat-icon>
                <mat-icon class="menu-button" [ngClass]="{'rotated' : showSubSubMenu1}" *ngIf="isExpanded || isShowing">expand_more</mat-icon>
            </mat-list-item>
            <div class="submenu" [ngClass]="{'expanded' : showSubSubMenu1}" *ngIf="showSubSubMenu1 && isExpanded">
                <mat-list-item routerLink="/master/reports">Stage Report<br><mat-icon mat-list-icon>sort</mat-icon></mat-list-item>
                <mat-list-item routerLink="/master/filterReport">Filter Report<br><mat-icon mat-list-icon>trending_up</mat-icon></mat-list-item>
            </div>     -->
           
            <mat-list-item *ngIf="account.role=='Admin'" (click)="showSubmenu = !showSubmenu" class="parent" routerLink="/master/manageUsers">
                <span class="full-width menu-body">Manage Users</span><br>

                <mat-icon mat-list-icon>group_work</mat-icon>
            </mat-list-item>
            <mat-list-item *ngIf="account.role=='Admin'" (click)="showSubSubMenu = !showSubSubMenu" class="parent">
                <span class="full-width">Settings</span><br>
                <mat-icon mat-list-icon>settings</mat-icon>
                <mat-icon class="menu-button" [ngClass]="{'rotated' : showSubSubMenu}" *ngIf="isExpanded || isShowing">expand_more</mat-icon>
            </mat-list-item>
            <div class="submenu" [ngClass]="{'expanded' : showSubSubMenu}" *ngIf="showSubSubMenu && isExpanded">
                <mat-list-item routerLink="/master/invoiceSettings">Invoice<br><mat-icon mat-list-icon>plagiarism</mat-icon></mat-list-item>
                <mat-list-item routerLink="/master/change-password">Change Password<br><mat-icon mat-list-icon>lock_open</mat-icon></mat-list-item>
                <mat-list-item routerLink="/master/email-settings">Email Service<br><mat-icon mat-list-icon>email</mat-icon></mat-list-item>
                <mat-list-item nrouterLink="/master/manageCompany">Company<br><mat-icon mat-list-icon>business</mat-icon></mat-list-item>
            </div>
            <!-- <mat-list-item *ngIf="account.role=='Admin'" (click)="showSubmenu = !showSubmenu" class="parent" routerLink="/master/email-settings">
                <span class="full-width menu-body">Email Service</span><br>

                <mat-icon mat-list-icon>email</mat-icon>
            </mat-list-item> -->
            <!-- <mat-list-item (click)="showSubmenu = !showSubmenu" class="parent" routerLink="/master/change-password">
                <span class="full-width menu-body">Change Password</span><br>

                <mat-icon mat-list-icon>lock_open</mat-icon>
            </mat-list-item> -->
            <mat-list-item (click)="showSubmenu = !showSubmenu" class="parent" (click)="logout()">
                <span class="full-width menu-body">Logout</span><br>

                <mat-icon mat-list-icon>exit_to_app</mat-icon>
            </mat-list-item>
        </mat-nav-list>
    </mat-sidenav>
    <alert></alert>
    <div class="mt-sidenav-content">
        <main>
            <div class="content">
                <router-outlet></router-outlet>
            </div>
        </main>
    </div>

</mat-sidenav-container>