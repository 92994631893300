import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less']
})
export class FooterComponent implements OnInit {
  routerURL: string;
  constructor(private router: Router) {
    setTimeout(() => {
      this.routerURL = router.url; 
    }, 1200);
   }

   /**
   * Check if the router url contains the specified route
   *
   * @param {string} route
   * @returns
   * @memberof MyComponent
   */
   hasRoute(route: string) {

    return this.router.url.includes(route);
  }

  ngOnInit(): void {
  }

}
