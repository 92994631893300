import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { AccountService } from './_services/account.service';

@Injectable({
  providedIn: 'root'
})
export class SharedServiceService {
  data: any;
  dataChange: Observable<any>;
  dataChangeObserver

  constructor(private accountService: AccountService) { 
    this.dataChange = new Observable(observer=>{
      this.dataChangeObserver = observer;
    });
  }

  setTitlePageData(data:any) {
    this.data = data;
    this.dataChangeObserver.next(this.data);
  }
}
