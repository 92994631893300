import { Component, OnInit, ViewChild, AfterViewInit, AfterViewChecked, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { AccountService } from '@app/_services';
import { SharedServiceService } from '../shared-service.service';
import { Observable, Subscription } from 'rxjs';
import { User } from '@app/_models';
import { BreakpointObserver } from '@angular/cdk/layout';
import { NavigationEnd, Router } from '@angular/router';
import { delay, filter } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.less']
})
export class SidebarComponent implements OnInit, AfterViewInit, AfterViewChecked,OnDestroy {
  @ViewChild('sidenav',{static:true}) sidenav: MatSidenav;
  opened = false;
  isExpanded = true;
  showSubmenu: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;
  showSubSubMenu1: boolean = false;
  pageTitle:string;
  account:any;
  //user:any=this.accountService.userValue;
  user : Observable<any>;
  userName:string;
  pageTitleSubscription$: Subscription;
  routerURL: string;
  constructor(private observer: BreakpointObserver, private router: Router, private changeDetector : ChangeDetectorRef, private accountService: AccountService, private sharedserviceservice:SharedServiceService) { 
    
    setTimeout(() => {
      this.routerURL = router.url; 
    }, 1200);
    
  }

  /**
   * Check if the router url contains the specified route
   *
   * @param {string} route
   * @returns
   * @memberof MyComponent
   */
   hasRoute(route: string) {

    return this.router.url.includes(route);
  }
  
  ngOnInit(): void {
    this.user = this.accountService.logUser;
    if(this.accountService.userValue){
      this.userName = this.accountService.userValue.firstName;
      this.account = this.accountService.userValue;
    }
    else
    this.userName = null;
  }

  ngAfterViewInit() {
    this.observer
      .observe(['(max-width: 1180px)'])
      .pipe(delay(1), untilDestroyed(this))
      .subscribe((res) => {
        
        if (res.matches) {
          this.opened =false;
         /* this.sidenav.mode = 'over'; */
          //this.sidenav.close();
        } else {
          this.opened = true;
          /*this.sidenav.mode = 'side'; */
          //this.sidenav.open();
        }
      });
    this.router.events
      .pipe(
        untilDestroyed(this),
        filter((e) => e instanceof NavigationEnd)
      )
      .subscribe(() => {
        /* if (this.sidenav.mode === 'over') {
          this.sidenav.close();
        } */
      });
  }

  isBiggerScreen() {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (width < 1180) {
      return true;
    } else {
      return false;
    }
  }

  ngAfterViewChecked() {
   
    setTimeout(()=>{
      this.pageTitleSubscription$ =this.sharedserviceservice.dataChange.subscribe((data) => {
        
        this.pageTitle = data.title;
      }); 
    }, 0)
    this.changeDetector.detectChanges();
  }

  ngOnDestroy(): void {
    this.pageTitleSubscription$.unsubscribe();
  }
  
  mouseenter() {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  mouseleave() {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }

  logout() {
    this.accountService.logout();
  }
}
