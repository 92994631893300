export class Order{
    id: string;
    is_new_client: boolean;
    order_stage: number;
    remarks: string;
    isactive: boolean;
    createdAt: string;
    updatedAt: string;
    deletedAt: string;
    clientId: string;
    Client:[''];
    Managers:[''];
    Coordinators:[''];
    vendors:[''];
    Products:[''];
    

}