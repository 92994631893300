<div class="modal-header">
    <h2 class="modal-title">Are you sure?</h2>
      <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      {{ message }}
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="decline()">Cancel</button>
      <button type="button" class="btn btn-primary" (click)="accept()">Confirm</button>
    </div>