import { Component, OnInit } from '@angular/core';
import { SnackBarService } from '@app/_services';
import { environment } from './../../environments/environment';

@Component({
  selector: 'app-welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.less']
})
export class WelcomePageComponent implements OnInit {

  clientShareLink = `${environment.MedLab_URL}#/public/order`;
  vendorShareLink = `${environment.MedLab_URL}#/public/vendor`;
  constructor(private snackBService: SnackBarService) { }

  ngOnInit(): void {
  }

  CopyToClipboard(){
    this.snackBService.openSnackBar("link copied", 'Close');
  }

}
