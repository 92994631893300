import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpAuthService {
  key = "key";
  version = "version";

  constructor(private http: HttpClient) { }

  public get(url: string, headers?: HttpHeaders | null): Observable<any> {
    const expandedHeaders = this.prepareHeader(headers);
    return this.http.get(url, expandedHeaders);
}

public post(url: string, body: any, headers?: HttpHeaders | null): Observable<any> {
    const expandedHeaders = this.prepareHeader(headers);
    return this.http.post(url, body, expandedHeaders);
}

public put(url: string, body: any, headers?: HttpHeaders | null): Observable<any> {
    const expandedHeaders = this.prepareHeader(headers);
    return this.http.put(url, body, expandedHeaders);
}

public delete(url: string, headers?: HttpHeaders | null): Observable<any> {
    const expandedHeaders = this.prepareHeader(headers);
    return this.http.delete(url, expandedHeaders);
}

public deleteAll(url: string, body: any, headers?: HttpHeaders | null): Observable<any> {
    headers = headers || new HttpHeaders();
    /* headers = headers.append('X-API-Key', `${this.key}`);
    headers = headers.append('X-API-Version', `${this.version}`); */
    const options = {
        headers: headers,
        body: body
    };
    return this.http.delete(url, options);
}

public patch(url: string, body: any, headers?: HttpHeaders | null): Observable<any> {
    const expandedHeaders = this.prepareHeader(headers);
    return this.http.patch(url, expandedHeaders);
}
public prepareHeader(headers: HttpHeaders | null): any {
    headers = headers || new HttpHeaders();
    /* headers = headers.append('X-API-Key', `${this.key}`);
    headers = headers.append('X-API-Version', `${this.version}`); */
    return {
        headers: headers
    };
}

}
