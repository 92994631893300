<!-- nav -->
<div class="logo less-c12g4">
    <img src="../../assets/logo/MedLab_logo.png">
</div>
<!-- <nav class="navbar navbar-expand navbar-dark bg-logo"> -->
<mat-toolbar class="mat-elevation-z8">
    <!-- <div class="navbar-nav">
        <a class="nav-item nav-link" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home</a>
        <a class="nav-item nav-link" routerLink="/users" routerLinkActive="active">Users</a>
        <a class="nav-item nav-link" (click)="logout()">Logout</a>
    </div> -->
    <button mat-icon-button *ngIf="sidenav.mode === 'over'" (click)="sidenav.toggle()">
        <mat-icon *ngIf="!sidenav.opened">
            menu
        </mat-icon>
        <mat-icon *ngIf="sidenav.opened">
            close
        </mat-icon>
    </button>
    Responsive side navigation

</mat-toolbar>
<!-- </nav> -->